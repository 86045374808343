body{
    background-color: #0f0c25;
    margin: 0;
    padding: 0;
    font-family: 'Muli', sans-serif;
    box-sizing: border-box;
    //transition: background 0.5s ease;
    //scroll-snap-type: y proximity;
    //scroll-padding-top: 15vh;

    *{box-sizing: border-box;}
    
    #root{
        position: relative;
    }

    .main-wrapper{
        //position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
    }

    hgroup{
        color: #fff;
        text-align: center;
        position: sticky;
        left: 0;
        top: 10px;
        width: 100%;

        h1{
            font-weight: normal;
            font-size: 60px;
            line-height: 70px;
            height: 75px;
            overflow: hidden;
            margin: 0;
            will-change: transform;
            @media (max-width: 768px) {
                font-size: 55px;
            }
        }
        p{font-weight: 300}
        b{
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            transition: transform 0.3s ease;
            span{white-space: nowrap;margin-bottom: 10px;}
        }
    }

    .content-wrapper{
        z-index: 2;
    }

    .card-wrapper{
        display: flex;
        flex-direction: column;
        &::before{
            content: "";
            width: 100vw;
            height: calc((100vh / 2.5) + 70px + 15vh);
            flex: 0 0 auto;
        }
    }

    .card{
        height: 70vh;
        width: 80vw;
        background: #fff;
        border-radius: 10px;
        flex: 0 0 auto;
        margin: 0 auto 15vh auto;
        position: sticky;
        top: 15vh;
        z-index: 2;
        box-shadow: 0 0 25px rgba(0,0,0,0.3);

        backface-visibility: hidden;
        perspective: 1000;

        overflow: hidden;
        display: flex;

        @media (max-width: 768px){
            flex-direction: column;
            width: 95vw;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }

        &.last .card-overlay{
            background-color: #0f0c25;
        }

        .card-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0;
            
            pointer-events: none;
        }

        .site-image{
            flex: 0 0 35%;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 768px){
                flex: 0 0 30%;
            }

            .image-holder{
                background-size: cover;
                background-position: center top;
                height: 50%;
                width: 50%;
                flex: 0 1 50%;
                position: relative;
                cursor: pointer;

                @media (max-width: 768px){
                    width: 30%;
                    flex: 1 1 30%;
                    height: 100%;

                    &:nth-of-type(2), &:nth-of-type(1){
                        border-right: 2px solid #fff;
                    }
                }

                @media (min-width: 768px){
                    &:first-of-type{
                        width: 100%;
                        flex: 0 1 100%;
                        border-bottom: 2px solid #fff;
                    }
                    &:nth-of-type(2){
                        border-right: 2px solid #fff;
                    }
                }
                &::before{
                    content: "VIEW";
                    font-weight: bold;
                    font-size: 20px;
                    color: #fff;
                    background: rgba(0,0,0,0.9);
                    mix-blend-mode: multiply;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.0001;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.2s ease;
                }
                &:hover::before{
                    opacity: 1;
                }
            }

            .fullscreen-image{
                width: 100%;
                height: 100%;
                overflow: hidden;
                visibility: hidden;
                img{width: 100%;}
            }
        }


        .site-desc{
            padding: 40px;
            flex: 1 1 auto;
            position: relative;

            h1{
                font-size: 32px;
            }
            
            p{
                max-width: 450px;
                font-size: 18px;
                line-height: 1.6em;
                margin-top: 30px;
                padding-left: 15px;
                margin-bottom: 0;
            }
            .mobile-gradient{
                display: none;
            }

            @media (max-width: 768px){
                padding: 20px;
                height: 70%;
                p {
                    font-size: 16px;
                    margin-top: 15px;
                    padding-left: 0px;
                    padding-bottom: 15px;

                    height: 100%;
                    max-height: calc(100% - 115px);
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                }
                .mobile-gradient{
                    display: block;
                    position: relative;
                    bottom: 30px;
                    width: 100%;
                    height: 30px;
                    background: linear-gradient(rgba(255,255,255,0), #fff);
                }
                h1 {
                    font-size: 22px;
                    margin: 0;
                    width: 100%;
                    small{
                        display: block;
                        margin: 5px 0 0 0;
                    }
                }
            }
        }

        h1{
            small{
                font-size: 14px;
                color: #828282;
                margin-left: 15px;
            }
        }

        .logos{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;

            img{
                height: auto;
                max-width: 60px;
                margin: 10px;
                filter: grayscale(100%);
                transition: filter 0.2s ease;
                &:hover{filter: grayscale(0%);}
            }

            @media (max-width: 768px){
                flex-wrap: nowrap;
                justify-content: space-evenly;
                bottom: 0;
                padding-bottom: 20px;

                img{
                    max-width: 30px;
                    flex: 0 0 auto;
                    margin: 0;
                }
            }

        }
    }

    .bottom-bits{
        position: fixed;
        top: calc(85vh - 30px);
        width: 80vw;
        left: 10vw;
        z-index: 1;
        display: flex;
        justify-content: center;

        @media (max-width: 768px){
            width: 95vw;
            left: 2.5vw;
        }

        &[data-cards-passed="0"]{
            .bottom{visibility: hidden}
        }
        &[data-cards-passed="1"]{
            .bottom:nth-of-type(2){visibility: hidden}
        }

        .bottom{
            position: absolute;
            border-radius: 0 0 10px 10px;
            background: #fff;
            &:nth-of-type(1){
                width: 96%;
                height: 41px;
                opacity: 0.66;
                @media (max-width: 768px){width: 95%;}
            }
            &:nth-of-type(2){
                width: 94%;
                height: 10px;
                top: 41px;
                opacity: 0.33;
                @media (max-width: 768px){width: 89%;}
            }
        }
    }

    .footer{
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: baseline;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 20px 0;
        z-index: 5;
        background: rgba(0,0,0,0.4);
        backdrop-filter: saturate(180%) blur(20px);
        transform: scale(1);
        transform-origin: bottom;
        *{margin: 0 25px 0 0; &:last-child{margin: 0}}
        a{color: #fff; text-decoration: none}

        @media (max-width: 768px){
            flex-direction: column;
            align-items: center;
            padding: 10px;
            *{margin: 0;}
            h2{display: none}
        }
    }

    .bands{
        position: fixed;
        top: 57vh;
        left: 0;
        width: 100%;
        //will-change: transform;
        > [class^="band"]{
            width: 100%;
            height: 15px;
            box-sizing: content-box;
            transform-origin: bottom;
        }
        .band-1{background: #005792}
        .band-2{background: #f08a5d}
        .band-3{background: #b83b5e}
        .band-4{background: #6a2c70}
        .band-5{background: #700961}

        &.balls{
            div:not(:first-of-type){
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: absolute;
                top: 15px;
                left: calc(50% - 15px);
                transform-origin: center;
                transition: transform 0.5s ease;
            }
        }
    }

    .lightbox{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow-y: scroll;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;

        padding: 40px 0;
        background: rgba(0, 0, 0, 0.4);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        backface-visibility: hidden;

        visibility: hidden;
        opacity: 0;
        transition: all 0.4s ease;

        .loading{
            filter: blur(15px)
        }
        
        img{
            width: 40%;
            height: auto;
            border-radius: 5px;

            @media (max-width: 768px){
                width: 95%;
            }
        }

        .close-button{
            position: sticky;
            top: -25px;
            background: rgba(19, 19, 19, 0.8);
            color: rgba(162, 162, 162, 0.8);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            font-size: 40px;
            line-height: 45px;
            margin: -25px 0 0 -25px;
            cursor: pointer;

            @media (max-width: 768px){
                position: fixed;
                top: 15px;
                right: 0px;
                margin: 0;
            }
        }
    }
    .lightbox.open{
        visibility: visible;
        opacity: 1;
    }

    .animated-loader{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 70px;
        height: 70px;
        border: 4px solid #757575;
        border-top-color: rgb(117, 117, 117);
        border-radius: 50%;
        border-top-color: #dfdfdf;

        animation: spin 1s ease infinite;
    }
}

@keyframes spin{
    0%{transform: translate(-50%,-50%) rotate(0deg)}
    0%{transform: translate(-50%,-50%) rotate(360deg)}
}